import { Card, Typography } from "@material-ui/core";
import { Paper, Button } from "@mui/material";

import AddToCartButton from "./AddToCartButton";
import placeholder_item from "../../../images/placeholder_item.png";
import MyWebServices from "~/services/WebServices";
import Capitalize from "~/views/common/Capitalize";
import veg from "../../../images/veg.png";
import newNonVeg from "../../../images/newNonVeg.png";
import Carousel from "react-material-ui-carousel";
import MenuItemMultipleImages from "./MenuItemMultipleImages";
import { useState } from "react";

function MenuItem({
  index,
  category,
  item,
  profile,
  isOpen,
  isViewMode,
  isSelected,
  itemBasePrice,
  handleSheetState,
  calculatedPrice,
  handleAddToCart,
  handleSomeData,
}) {
  const [imagesModalOpen, setImagesModalOpen] = useState(false);
  const currency = profile.currency ? profile.currency : "₹";
  const getImageHeight = () => {
    const available = window.innerWidth;
    if (available > 500) {
      return 187;
    }
    return ((available / 2 - 16) * 3) / 4;
  };

  const vegOrNonVegOrNone = () => {
    if (item.veg === null) {
      return null;
    }
    return (
      <img
        src={item.veg ? veg : newNonVeg}
        width="24px"
        style={{
          display: "-webkit-inline-box",
          transform: "translate(-3px, 5px)",
        }}
      />
    );
  };

  const handleModalClose = () => setImagesModalOpen(false);

  const getDiscount = () => {
    if (item.discount !== 0 && category.discount !== 0) {
      return `${item.discount}%`;
    }
    if (category.discount !== 0) {
      return "";
    }
    if (item.discount !== 0) {
      return `${item.discount}%`;
    }
    return "";
  };

  const tagComponent = (element, textColor) => {
    return (
      <Typography
        variant="p"
        style={{
          fontWeight: "700",
          fontSize: "12px",
          color: textColor,
          backgroundImage: `linear-gradient(120deg, #00000070, #00000070)`,
          padding: "0 .5rem .2rem .5rem",
          marginRight: ".4rem",
          borderRadius: ".5rem",
        }}
      >
        {element}&nbsp;
      </Typography>
    );
  };

  const itemTags = () => {
    const tags = [];
    for (let index = 0; index < item.tags.length; index++) {
      const element = item.tags[index];
      if (index !== 0 && index % 2 === 0) {
        tags.push(<br />);
      }

      if (element === "Best Seller") {
        tags.push(tagComponent(element, "#FFF"));
      } else if (element === "Top Rated") {
        tags.push(tagComponent(element, "#FFF"));
      } else if (element === "Egg") {
        tags.push(tagComponent(element, "#FFF"));
      } else if (element === "Spicy") {
        tags.push(tagComponent(element, "#FFF"));
      } else if (element === "Must Try") {
        tags.push(tagComponent(element, "#FFF"));
      } else if (element === "Chef Special") {
        tags.push(tagComponent(element, "#FFF"));
      } else {
        tags.push(tagComponent(element, "#FFF"));
      }
    }
    return tags;
  };

  function ItemMultipleImages() {
    if (item.images.length === 1) {
      return (
        <img
          src={item.images[0]}
          className="lazyload blur-up"
          style={{
            width: "-webkit-fill-available",
            height: getImageHeight(),
            objectFit: "cover!important",
            backgroundRepeat: "no-repeat!important",
            backgroundPosition: "center!important",
            backgroundSize: "cover!important",
            borderTopRightRadius: index % 2 == 0 ? "1rem" : "0px",
            borderBottomLeftRadius: index % 2 == 0 ? "1rem" : "0px",
            borderTopLeftRadius: index % 2 != 0 ? "1rem" : "0px",
            borderBottomRightRadius: index % 2 != 0 ? "1rem" : "0px",
          }}
        />
      );
    }
    if (item.images.length) {
      return (
        <Carousel
          cycleNavigation
          duration={700}
          indicators={true}
          animation="swipe"
          stopAutoPlayOnHover
          navButtonsAlwaysVisible
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
              padding: "0px",
              // backgroundImage: `linear-gradient(120deg, #00000070, #00000070)`,
            },
          }}
        >
          {item.images.map((image, i) => (
            <Item key={i} image={image} />
          ))}
        </Carousel>
      );
    }
    return (
      <img
        src={placeholder_item}
        className="lazyload blur-up"
        style={{
          width: "-webkit-fill-available",
          height: getImageHeight(),
          objectFit: "cover!important",
          backgroundRepeat: "no-repeat!important",
          backgroundPosition: "center!important",
          backgroundSize: "cover!important",
          borderTopRightRadius: index % 2 == 0 ? "1rem" : "0px",
          borderBottomLeftRadius: index % 2 == 0 ? "1rem" : "0px",
          borderTopLeftRadius: index % 2 != 0 ? "1rem" : "0px",
          borderBottomRightRadius: index % 2 != 0 ? "1rem" : "0px",
        }}
      />
    );
  }

  function Item({ image }) {
    return (
      <Paper
        style={{ cursor: "pointer" }}
        onClick={() => {
          console.log("IMAGE PE CLICK");
          setImagesModalOpen(true);
        }}
      >
        <img
          src={image}
          className="lazyload blur-up"
          style={{
            width: "-webkit-fill-available",
            height: getImageHeight(),
            objectFit: "cover!important",
            backgroundRepeat: "no-repeat!important",
            backgroundPosition: "center!important",
            backgroundSize: "cover!important",
            borderTopRightRadius: index % 2 == 0 ? "1rem" : "0px",
            borderBottomLeftRadius: index % 2 == 0 ? "1rem" : "0px",
            borderTopLeftRadius: index % 2 != 0 ? "1rem" : "0px",
            borderBottomRightRadius: index % 2 != 0 ? "1rem" : "0px",
          }}
        />
      </Paper>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <div style={{ zIndex: "999", position: "absolute" }}>
        {item.tags.length ? itemTags() : null}
      </div>

      <ItemMultipleImages />
      <MenuItemMultipleImages
        imagesData={item.images}
        open={imagesModalOpen}
        handleClose={handleModalClose}
      />

      <Card
        style={{
          margin: "0 4px",
          padding: "6px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "16px",
          borderTopRightRadius: "16px",
          transform: "translate(0px,-20px)",
          boxShadow:
            "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
        }}
      >
        <Typography align="center" variant="p" style={{ fontWeight: "700" }}>
          {vegOrNonVegOrNone()}
          &nbsp;
          {Capitalize(item.label)}
        </Typography>
        <Typography
          align="left"
          variant="p"
          style={{
            fontSize: "16px",
            marginTop: ".4rem",
            marginBottom: "1.4rem",
          }}
        >
          <br />
          {getDiscount() || category.discount ? (
            <>
              <span style={{ color: "red", fontSize: "12px" }}>
                {getDiscount() ? <i>{`${getDiscount()} OFF`}</i> : " "}
              </span>
              <br />
              <span style={{ color: "red" }}>
                <s>
                  <em>{`${currency} ${itemBasePrice}`}</em>{" "}
                </s>
              </span>
              <span style={{ marginLeft: "1rem" }}>
                <em>{`${currency} ${Math.round(calculatedPrice)}`}</em>
              </span>
            </>
          ) : (
            <span>
              <em>{`${currency} ${Math.round(calculatedPrice)}`}</em>
            </span>
          )}
        </Typography>
        <br />

        <AddToCartButton
          category={category}
          item={item}
          profile={profile}
          isOpen={isOpen}
          isViewMode={isViewMode}
          isSelected={isSelected}
          calculatedPrice={calculatedPrice}
          handleSheetState={handleSheetState}
          handleAddToCart={handleAddToCart}
          handleSomeData={handleSomeData}
        />
      </Card>
    </div>
  );
}

export default MenuItem;
