import React, { useEffect, useState } from "react";
import Page from "~/components/Page";
import { useParams } from "react-router";
import MyWebServices from "~/services/WebServices";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, Divider, Grid } from "@material-ui/core";
import LoadingScreen from "~/components/LoadingScreen";
import HorizontalLinearStepper from "./stepper";
import ThemeConfig from "~/theme";
import { Helmet } from "react-helmet";
import MenuProfile from "../../menu/MenuView/MenuProfile";
import {
  getOrdersListFromLocalDB,
  getPrimaryColorFromLocalDB,
  setOrdersListInLocalDB
} from "~/services/LocalServices";
import PaymentDetail from "./PaymentDetail";
import OrderItemTile from "./OrderItemTile";
import OrderDetail from "./OrderDetail";
// -----------------------------------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {},
  orderNumberContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 .4rem",
    marginTop: "1rem",
    marginBottom: ".2rem"
  },
  orderNumberAndType: {
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
    borderRadius: ".5rem",
    padding: "0 0.4rem 0.2rem 0.4rem"
  }
}));

function OrderStatus(props) {
  const classes = useStyles();
  const { history } = props;
  const { slug } = useParams();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [orderStatus, setOrderStatus] = useState(undefined);
  const primaryColorinLocalDB = getPrimaryColorFromLocalDB("primaryColor");
  const primaryColor = props.location.state
    ? props.location.state.primaryColor
    : primaryColorinLocalDB
    ? primaryColorinLocalDB
    : "00AB55";

  useEffect(() => {
    const orderStatusNetworkCall = async () => {
      await MyWebServices.getOrderStatusNodeJs(slug, "menu")
        .then(response => {
          if (response.status != 200) {
            setErrorMessage(response.data);
          }
          console.log("response.data", response.data);
          setOrderStatus(response.data);
        })
        .catch(error => {
          console.log("error\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n", error);
          setErrorMessage(error);
        });
    };
    orderStatusNetworkCall();
  }, []);

  // ********************************************************************************************************
  useEffect(() => {
    if (orderStatus) setOrderListInLocalStorage(orderStatus);
  }, [orderStatus]);

  // ******************************Set OrdersList in Local Storage*******************************************
  const setOrderListInLocalStorage = responseData => {
    const {
      _id,
      slug,
      orderNo,
      items,
      remarks,
      profileID,
      createdAt,
      amount
    } = responseData;

    const objectData = {
      orderNumber: orderNo,
      itemsList: items,
      user: responseData.user, //Todo...
      remarks: remarks,
      address: profileID.address,
      value: amount,
      orderId: _id,
      orderSlug: slug,
      orderTime: createdAt,
      profile: profileID,
      config: props.location.state ? props.location.state.config : null
    };
    const ordersListFromLocalStorage = getOrdersListFromLocalDB("ordersList");
    if (ordersListFromLocalStorage) {
      const newOrdersList = JSON.parse(ordersListFromLocalStorage);
      let itWasUpdated = false;
      for (let index = 0; index < newOrdersList.length; index++) {
        const element = newOrdersList[index];
        if (element.orderId === responseData._id) {
          newOrdersList[index] = objectData;
          setOrdersListInLocalDB("ordersList", JSON.stringify(newOrdersList));
          itWasUpdated = true;
          break;
        }
      }
      if (!itWasUpdated) {
        newOrdersList.push(objectData);
        setOrdersListInLocalDB("ordersList", JSON.stringify(newOrdersList));
      }
      return;
    }
    setOrdersListInLocalDB("ordersList", JSON.stringify([objectData]));
  };

  // **********************************Calculating Cart Value**********************************************

  // **********************************Calculating Total Bill***********************************************
  const getGrandTotal = () => {
    let sum = 0;
    if (orderStatus) {
      for (let index = 0; index < orderStatus.charges.length; index++) {
        const element = orderStatus.charges[index];
        sum += Number(element.value);
      }
    }
    return sum;
  };

  // *********************************************************************************************************

  if (errorMessage) {
    return (
      <Page title="OctopusCo | Cart" className={classes.root}>
        <Typography align="center" variant="h3" component="h3" gutterBottom>
          {errorMessage}
        </Typography>
      </Page>
    );
  }

  // *********************************************************************************************************

  if (typeof orderStatus === "undefined") {
    return (
      <Page
        title="OctopusCo | Cart"
        className={classes.root}
        style={{
          margin: "64px"
        }}
      >
        <LoadingScreen />
      </Page>
    );
  }

  // *********************************************************************************************************
  const { currency } = orderStatus.profileID;

  return (
    <ThemeConfig primaryColor={primaryColor}>
      <Helmet>
        <meta name="theme-color" content={`#${primaryColor}`} />
      </Helmet>
      <Page title="OctopusCo | Order Status" className={classes.root}>
        <MenuProfile profile={orderStatus.profileID} />
        <HorizontalLinearStepper orderStatus={orderStatus} />

        <div className={classes.orderNumberContainer}>
          <Typography
            variant="subtitle1"
            alignSelf="center"
            className={classes.orderNumberAndType}
          >
            Order Number: {orderStatus.orderNo}
          </Typography>
          <Typography
            variant="subtitle1"
            alignSelf="center"
            className={classes.orderNumberAndType}
          >
            Order Type: {orderStatus.orderType}
          </Typography>
        </div>

        {orderStatus.profileID.slug === "the-kasturi-restaurant" &&
        orderStatus.state !== 5 ? (
          <Typography variant="body1" alignSelf="center" sx={{ mb: 1 }}>
            Your Order will be served in 20 to 30 minutes.
            <br />
            Thanks for your order!
          </Typography>
        ) : null}
        {/* <PaymentDetail orderStatus={orderStatus} /> */}
        <Card sx={{ p: 1, mt: 2 }}>
          <Grid container spacing={2}>
            {orderStatus.items.map(orderItem => (
              <OrderItemTile
                key={orderItem._id}
                orderItem={orderItem}
                currency={currency}
              />
            ))}
          </Grid>
        </Card>
        <OrderDetail orderStatus={orderStatus} />
      </Page>
    </ThemeConfig>
  );
}

export default OrderStatus;
