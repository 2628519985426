import TermsAndConditionPolicy from "./TermsAndConditionModal";
import ReturnRefundPolicy from "./ReturnRefundModal";
import PrivacyPolicy from "./PrivacyPolicyModal";
import AboutUsPolicy from "./AboutUsModal";
import fssaiImage from "../../../images/fssai.png";
import { Divider, Typography, Grid } from "@material-ui/core";

function StorePolicies({ profile }) {
  console.log("profile in StorePolicies", profile);
  const { legalName } = profile;
  if (legalName) {
    const { slug, fssaiNo } = profile;
    const { street, city, pinCode, state, country } = profile.address;
    // return
    return (
      <Grid container spacing={3} style={{ paddingTop: "1.7rem" }}>
        <Grid item xs={12}>
          <Typography variant="p">
            {`* ${legalName}`},&nbsp;
            {street}
            {city}, {pinCode}, {state}, {country}.
            <br />
          </Typography>

          <AboutUsPolicy profile={profile} />
          <PrivacyPolicy profile={profile} menuSlug={slug} />
          <ReturnRefundPolicy profile={profile} menuSlug={slug} />
          <TermsAndConditionPolicy profile={profile} menuSlug={slug} />
          <Divider />
          <div style={{ display: "flex" }}>
            <img
              style={{
                width: "50px",
                height: "25px"
              }}
              src={fssaiImage}
            />
            <Typography
              variant="span"
              align="left"
              style={{ marginLeft: "1rem" }}
            >
              {fssaiNo}
            </Typography>
          </div>
          <Divider />
        </Grid>
      </Grid>
    );
  }
  return null;
}

export default StorePolicies;
