import React from "react";
import MyAvatar from "~/components/MyAvatar";
import { Box, Typography } from "@material-ui/core";
import yourLogo from "../../../images/yourLogo.png";

// ----------------------------------------------------------------------

function MenuProfile({ profile }) {
  return (
    <div style={{ marginBottom: "12px" }}>
      <MyAvatar
        src={profile.logo ? profile.logo : yourLogo}
        sx={{
          mx: "auto",
          borderWidth: 2,
          borderStyle: "solid",
          width: { xs: 180, md: 148 },
          height: { xs: 180, md: 148 },
          BorderRadius: "10%",
        }}
      />

      <Box
        sx={{
          ml: { md: 3 },
          mt: { xs: 1, md: 0 },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h3" align="center">
          {profile.name ? profile.name : "Business Name"}
        </Typography>

        <Box align="center" sx={{ typography: "subtitle1", opacity: 0.72 }}>
          {profile.description
            ? profile.description
            : "Customers can order anytime, anywhere."}
        </Box>
      </Box>
    </div>
  );
}

export default MenuProfile;
