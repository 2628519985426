import PalleteColor from "./palette";
import ShadowsGlobal from "./shadows";
import PropTypes from "prop-types";
import typography from "./typography";
import breakpointsX from "./breakpoints";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import componentsOverride from "./overrides";
import GlobalStyles from "./globalStyles";
import borderRadius from "./borderRadius";
import { StyledEngineProvider } from "@material-ui/styled-engine";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { getPrimaryColorFromLocalDB } from "~/services/LocalServices";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

const primaryColorFromLocalDB = getPrimaryColorFromLocalDB("primaryColor");

function ThemeConfig({
  children,
  primaryColor = primaryColorFromLocalDB ? primaryColorFromLocalDB : "00AB55"
}) {
  const isLight = useSelector(state => !state.theme.darkMode);

  const themeOptions = {
    palette: PalleteColor(primaryColor)[isLight ? "light" : "dark"],
    shadows: ShadowsGlobal(primaryColor)[isLight ? "light" : "dark"],
    typography: typography,
    shape: borderRadius,
    breakpoints: breakpointsX,

    components: componentsOverride({
      theme: {
        palette: PalleteColor(primaryColor)[isLight ? "light" : "dark"],
        shadows: ShadowsGlobal(primaryColor)[isLight ? "light" : "dark"],
        typography: typography,
        shape: borderRadius
      }
    })
  };

  if (!isLight) themeOptions.palette.mode = "dark";

  const theme = useMemo(() => createMuiTheme(themeOptions), [themeOptions]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default ThemeConfig;
