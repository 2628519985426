import "./App.css";
import React, { Suspense, Fragment } from "react";
import ThemeConfig from "./theme";
import GuestProtect from "~/components/Auth/GuestProtect";
import { createBrowserHistory } from "history";
import ScrollToTop from "~/components/ScrollToTop";
import GoogleAnalytics from "~/components/GoogleAnalytics";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import NotistackProvider from "~/components/NotistackProvider";
import FirebaseProvider from "~/components/Auth/FirebaseProvider";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import MenuLayout from "~/layouts/MenuLayout";
import ExampleMenuLayout from "~/layouts/ExampleMenuLayout";

// Internal Import
import MenuView from "./views/menu/MenuView";
import LoadingScreen from "./components/LoadingScreen";
import OrderStatus from "./views/orders/OrderStatus";
import PlaceFoodOrder from "./views/placeOrder/PlaceOrderView";
import StoreChoice from "./views/menu/MenuView/StoreChoice";
import {
  getStoreFromLocalDB,
  removePrimaryColor
} from "./services/LocalServices";
import OrdersList from "./views/orders/OrderHistory/OrdersList";

// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  const Layout = MenuLayout || Fragment;
  const Guard = GuestProtect || Fragment;

  // console.log(`
  //    ░░░      ░░░  ░░  ░░░    ░░  ░░░░░░  ░░░░░░  ░░░░░░
  //      ▒▒    ▒▒    ▒▒  ▒▒▒▒   ▒▒  ▒▒      ▒▒        ▒▒
  //       ▒▒  ▒▒     ▒▒  ▒▒  ▒▒ ▒▒  ▒▒▒▒    ▒▒▒▒      ▒▒
  //        ▓▓▓▓      ▓▓  ▓▓   ▓▓▓▓  ▓▓      ▓▓        ▓▓
  //         ██       ██  ██     ██  ██████  ██████    ██
  //     `)

  const octap = `

           ░░░░   ░░░░ ░░░░░░ ░░░░░░ ░░░░░░
          ▒▒  ▒▒ ▒▒      ▒▒   ▒▒  ▒▒ ▒▒  ▒▒
          ▒▒  ▒▒ ▒▒      ▒▒   ▒▒▒▒▒▒ ▒▒▒▒▒▒
          ▓▓  ▓▓ ▓▓      ▓▓   ▓▓  ▓▓ ▓▓    
           ████   ████   ██   ██  ██ ██    
           
  `;

  console.info(`%c${octap}`, "color: #0277BD");

  const homePageComponent = () => {
    const storeListData = getStoreFromLocalDB("storeList");
    if (storeListData) {
      const storeList = JSON.parse(storeListData);
      if (storeList.length > 1) {
        return <StoreChoice storeList={storeList} />;
      } else {
        if (storeList.length === 1) {
          return <Redirect to={`/${storeList[0].slug}`} />;
        }
      }
    }
    return <MenuView />;
  };

  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotistackProvider>
          <Router history={history}>
            <FirebaseProvider>
              <ScrollToTop />
              <GoogleAnalytics />
              <Suspense fallback={<LoadingScreen />}>
                <Layout>
                  <Switch>
                    <Route
                      path="/place-order"
                      exact
                      component={PlaceFoodOrder}
                    />
                    <Route path="/orders" exact component={OrdersList} />
                    <Route path="/status/:slug" exact component={OrderStatus} />
                    <Route path="/:slug" exact component={MenuView} />
                    <Route path="/" exact render={homePageComponent} />
                  </Switch>
                </Layout>
              </Suspense>
            </FirebaseProvider>
          </Router>
        </NotistackProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}

export default App;
