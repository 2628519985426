import React, { useEffect, useState } from "react";
import ChatBotPage from "~/components/ChatBotPage";
import { useParams } from "react-router";
import ExampleMenuFooter from "./ExampleMenuFooter";
import queryString from "query-string";
import MyWebServices from "../../../services/WebServices";
import MenuChildViewNew from "./MenuChildViewNew";
import LoadingScreen from "~/components/LoadingScreen";
import MultipleMenuChoiceView from "./MultipleMenuChoiceView";
import MenuProfile from "~/views/menu/MenuView/MenuProfile";
import { Typography } from "@material-ui/core";
import exampleMenuResponseData from "../../../exampleMenu.json";
import Page404View from "../../errors/Page404View";
import {
  getStoreFromLocalDB,
  setStoreInLocalDB,
} from "~/services/LocalServices";

function MenuView(props) {
  const { slug } = useParams();
  const [slugResponseData, setSlugResponseData] = useState({});
  const [showCartPage, setShowCartPage] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);

  console.log(
    "slugResponseData\nslugResponseData\nslugResponseData\nslugResponseData\nslugResponseData\nslugResponseData\n",
    slugResponseData
  );
  const handleShowCart = (value) => {
    setShowCartPage(value);
  };

  const slugResponseDataInLocalStorage = (responseData) => {
    const storeList = getStoreFromLocalDB("storeList");
    if (storeList) {
      const newStoreList = JSON.parse(storeList);
      const result = newStoreList.every(
        (store) => store.slug !== responseData.profile.slug
      );
      if (result) {
        newStoreList.push(responseData.profile);
        setStoreInLocalDB("storeList", JSON.stringify(newStoreList));
      }
    } else {
      setStoreInLocalDB("storeList", JSON.stringify([responseData.profile]));
    }
  };

  if (!slug) {
    console.log("Yaha Agya.....");
    return (
      <MenuChildViewNew
        slugResponseData={exampleMenuResponseData}
        menu={exampleMenuResponseData.menus[0]}
      />
    );
  } else {
    useEffect(() => {
      MyWebServices.getMenuNodeJs(0, slug)
        .then((response) => {
          console.log("response", response);
          setSlugResponseData(response.data);
          slugResponseDataInLocalStorage(response.data);
        })
        .catch((error) => {
          console.log(error == "Error: Request failed with status code 404");
          setPageNotFound(
            error == "Error: Request failed with status code 404"
          );
        });
    }, []);
  }

  if (pageNotFound) {
    return (
      <div>
        <Page404View />
      </div>
    );
  }

  if (JSON.stringify(slugResponseData) === "{}") {
    return (
      <div style={{ margin: "64px" }}>
        <LoadingScreen />
      </div>
    );
  }

  const { search } = props.location;
  const urlParams = queryString.parse(search);
  const { conf, men, iden, idel } = urlParams;

  if (men) {
    for (let index = 0; index < slugResponseData.menus.length; index++) {
      const element = slugResponseData.menus[index];
      if (element.id == men) {
        return (
          <MenuChildViewNew
            slugResponseData={slugResponseData}
            menu={element}
            conf={conf}
            iden={iden}
            idenl={idel}
            showCartPage={showCartPage}
            handleShowCart={handleShowCart}
          />
        );
      }
    }
  }

  let listOfMenus = [];
  for (let index = 0; index < slugResponseData.menus.length; index++) {
    const element = slugResponseData.menus[index];
    listOfMenus.push(element);
  }

  if (listOfMenus.length > 1) {
    return (
      <MultipleMenuChoiceView
        slugResponseData={slugResponseData}
        menus={listOfMenus}
        conf={conf ? conf.replace(/\/$/, "") : conf}
        iden={iden}
        idenl={idel}
        showCartPage={showCartPage}
        handleShowCart={handleShowCart}
      />
    );
  }
  if (!listOfMenus.length) {
    return (
      <div
        style={{
          boxShadow: "none",
          marginTop: "5rem",
          marginBlockEnd: "3rem",
        }}
      >
        <MenuProfile profile={slugResponseData.profile} />
        <Typography variant="h4" align="center">
          Store is offline at the moment.
          <br />
          Please check back later!
        </Typography>
        <ExampleMenuFooter />
      </div>
    );
  }
  return (
    <MenuChildViewNew
      slugResponseData={slugResponseData}
      menu={listOfMenus[0]}
      conf={conf ? conf.replace(/\/$/, "") : conf}
      iden={iden}
      idenl={idel}
      showCartPage={showCartPage}
      handleShowCart={handleShowCart}
    />
  );
}

export default MenuView;
